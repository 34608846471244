import React from 'react';

function Features() {
    return (
        <section
            className="resume py-5 d-lg-flex justify-content-center align-items-center"
            id="resume"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <h2 className="mb-4">Experiences</h2>

                        <div className="timeline">
                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022-3</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                                        <span>BackEnd Developer</span><small>Nur ul-Islam</small>
                                    </h3>
                                    <p>
                                        I starting new star-up with name Nur ul-Islam and collecting
                                        new 'idea one' team. Our goal is gain expensive experiences
                                        and help for Ummah. Our projects is
                                        <a
                                            href="https://cartouch.ai/"
                                            className="project-link"
                                            target="_blank"
                                        >Nur ul-Islam</a
                                        >,
                                        <a
                                            href="https://startups.financialit.net"
                                            className="project-link"
                                            target="_blank"
                                        >OSavdo Uz</a
                                        >,
                                        <a
                                            href="https://tezparcel.com/"
                                            className="project-link"
                                            target="_blank"
                                        >Order System</a
                                        >.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>BackEnd Developer</span><small>SiteLabs</small></h3>
                                    <p>
                                        I worked as an intern at SiteLabs for 2 months from August,
                                        then I started working as a backend developer. I worked in
                                        this company on projects such as
                                        <a
                                            href="https://cartouch.ai/"
                                            className="project-link"
                                            target="_blank"
                                        >Cartouch</a
                                        >,
                                        <a
                                            href="https://startups.financialit.net"
                                            className="project-link"
                                            target="_blank"
                                        >Financial IT</a
                                        >,
                                        <a
                                            href="https://tezparcel.com/"
                                            className="project-link"
                                            target="_blank"
                                        >TezParcel bot</a
                                        >
                                        and I gained a lot of experience for 6 months until April
                                        2023.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                                        <span>Java Developer</span><small>iSystem IT academy</small>
                                    </h3>
                                    <p>
                                        I graduated from iSystem IT academy in April 2022. Then I
                                        gain experience as a Back-end developer. We're worked on a
                                        quran app.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                                        <span>Java Teacher<small>IT Codding</small></span>
                                    </h3>
                                    <p>
                                        I gained experience in teaching Java from IT for 1 month on
                                        the IT Codding online learning platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <h2 className="mb-4 mobile-mt-2">Educations</h2>

                        <div className="timeline">
                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2022-3</span>
                                </div>
                                <div className="timeline-info">
                                    <h3><span>Self Study</span><small>Backend DevOps</small></h3>
                                    <p>
                                        I'm learning Backend and DevOps myself. I trying to get good
                                        knowledges, experiences and skills.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2021</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                    <span>iSystem IT academy</span
                    ><small>Java Backend Course</small>
                                    </h3>
                                    <p>
                                        I studied at this academy Java Backend Course and learned a
                                        lot of things belonging to the backend.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2021</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                    <span>iSystem IT academy</span
                    ><small>Foundation Course</small>
                                    </h3>
                                    <p>
                                        <a rel="nofollow" href="https://isystem.uz">iSystem</a> IT
                                        academy is an academy that specialize in teaching profitable
                                        future professions. I studied at this academy Foundation
                                        course for 3 months.
                                    </p>
                                </div>
                            </div>

                            <div className="timeline-wrapper">
                                <div className="timeline-yr">
                                    <span>2020</span>
                                </div>
                                <div className="timeline-info">
                                    <h3>
                    <span>Learning Skills Lab</span
                    ><small>English Course</small>
                                    </h3>
                                    <p>
                                        I studied English up to B2 at the 2020 (LSL) Learning
                                        Center.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;