import React, {useState} from 'react';
import Headroom from "react-headroom";
import {Link} from 'react-scroll';

function Navbar() {

    const [changeMode, setChangeMode] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)

    const handleChangeMode = () => {
        setChangeMode(prevState => !prevState)
        document.body.classList.toggle('dark-mode')
    }

    return (
        <Headroom>
            <nav className="navbar navbar-expand-sm navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#"
                    ><i className="uil uil-user"></i> Abdulazizkhan</a
                    >

                    <button
                        className={`navbar-toggler ${!openMenu && "collapsed"}`}
                        type="button"
                        onClick={() => setOpenMenu(prevState => !prevState)}
                    >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>

                    <div className={`collapse navbar-collapse ${openMenu && "active"}`} id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <i className="uil uil-times close" onClick={() => setOpenMenu(false)}></i>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    <span data-hover="About">
                                        About
                                    </span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="project"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    <span data-hover="Projects">
                                        Projects
                                    </span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="resume"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    <span data-hover="Resume">
                                        Resume
                                    </span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="skills"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    <span data-hover="Skills">
                                        Skills
                                    </span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    <span data-hover="Contact">
                                        Contact
                                    </span>
                                </Link>
                            </li>
                        </ul>

                        <ul className="navbar-nav ml-lg-auto">
                            <div className="ml-lg-4">
                                <div className="color-mode" onClick={handleChangeMode}>
                                    <i className={`color-mode-icon ${changeMode && 'active'}`}></i>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
        </Headroom>
    );
}

export default Navbar;