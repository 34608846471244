import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./styles/tooplate-style.css"
import "./styles/bootstrap.min.css"
import "./styles/mystyle.css"
import "./styles/unicons.css"
import "./assets/boxicons-2.1.4/css/boxicons.min.css"
import App from './App';
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
        <Toaster
            position="top-center"
            reverseOrder={false}
        />
    </React.StrictMode>
);
