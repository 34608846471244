import React from 'react';
import htmlImg from "../../assets/images/html.svg"
import cssImg from "../../assets/images/css.svg"
import postgresImg from "../../assets/images/postgressql.png"
import javaImg from "../../assets/images/java.png"
import springImg from "../../assets/images/spring.png"
import dockerImg from "../../assets/images/docker.png"

function Skills() {
    return (
        <section className="container" id="skills">
            <div className="sectionHead">
                <h2>SKILLS</h2>
            </div>

            <div className="skillsContainer sectionContainer">
                <ul className="skillsGrid">
                    <li>
                        <div className="itemContainer">
                            <img src={htmlImg} alt={'aknb'}/>
                            <p>HTML</p>
                        </div>
                    </li>
                    <li>
                        <div className="itemContainer">
                            <img src={cssImg} alt={'aknb'}/>
                            <p>CSS</p>
                        </div>
                    </li>
                    <li>
                        <div className="itemContainer">
                            <img src={postgresImg} alt={'aknb'}/>
                            <p>PostgresSQL</p>
                        </div>
                    </li>
                    <li>
                        <div className="itemContainer">
                            <img src={javaImg} alt={'aknb'}/>
                            <p>Java</p>
                        </div>
                    </li>
                    <li>
                        <div className="itemContainer">
                            <img src={springImg} alt={'aknb'}/>
                            <p>Spring</p>
                        </div>
                    </li>
                    <li>
                        <div className="itemContainer">
                            <img src={dockerImg} alt={'aknb'}/>
                            <p>Docker</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Skills;