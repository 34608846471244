import React from 'react';

function Footer() {
    return (
        <footer className="footer py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <p className="copyright-text text-center">
                            Copyright &copy; 2022 A.K.N.B . All rights reserved
                        </p>
                        <p className="copyright-text text-center">
                            Designed by
                            <a
                                rel="nofollow"
                                href="https://www.facebook.com/tooplate"
                                target="_blank"
                            >Tooplate</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;