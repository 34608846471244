import React, {useState} from 'react';
import toast from "react-hot-toast";
import {axiosInstance} from "../../api";

function Contact() {

    const handleCopyPhoneNumber = (phoneNumber) => {
        navigator.clipboard.writeText(phoneNumber);
    };

    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    const [values, setValues] = useState({
        fullName: "",
        email: "",
        message: "",
        secretKey: "rootan"
    })

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!values.fullName || !values.email || !values.message) return setIsValid(true);
        setIsValid(false);
        setLoading(true)
        await axiosInstance.post("/contact-me", values).then(res => {
            toast.success("Message Sended!")
            setValues({
                fullName: "",
                email: "",
                message: "",
                secretKey: "rootan"
            })
        }).catch(e => {
            toast.error(e?.response?.data[0]?.message)
        })
        setLoading(false)
    }

    return (
        <section className="contact py-5" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mr-lg-5 col-12" style={{margin: "20px 0"}}>
                        <div
                            className="contact-info d-flex justify-content-between align-items-center py-4 px-lg-5"
                        >
                            <div className="contact-info-item">
                                <h3 className="mb-3 text-white">Contact</h3>
                                <img src="./images/logo.png" className="my-logo" alt="png image"/>
                                <h5 className="fullname">Abdulazizkhan Karimbayev</h5>
                                <a
                                    className="footer-text mb-0 copy-number"
                                    title="Copy Phone"
                                    id="copy_phone"
                                    onClick={() => handleCopyPhoneNumber("+998(99) 566 60 90")}
                                >+998(99) 566 60 90</a>
                            </div>

                            <ul className="social-links">
                                <li>
                                    <a
                                        href="https://t.me/A_K_N_B"
                                        target="_blank"
                                        className="uil bx bxl-telegram"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Telegram"
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.instagram.com/_karimov.003_"
                                        target="_blank"
                                        className="uil bx bxl-instagram"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Instagram"
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="mailto:abdulazizkhan.k.n.b@gmail.com"
                                        className="uil bx bxl-gmail"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Email"
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.linkedin.com/in/abdulazizkhan-karimbayev-65928722a"
                                        target="_blank"
                                        className="uil bx bxl-linkedin"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Linkedin"
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="https://github.com/AbdulazizkhanKarimbayev"
                                        target="_blank"
                                        className="uil bx bxl-github"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="GitHub"
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="contact-form">
                            <h2 className="mb-4">Interested to work together? Let's talk</h2>

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <label htmlFor="name"></label>
                                        <input
                                            type="text"
                                            className={`form-control ${isValid && "error"}`}
                                            name="fullName"
                                            placeholder="  Your Name"
                                            id="name"
                                            value={values.fullName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <label htmlFor="email"></label>
                                        <input
                                            type="email"
                                            className={`form-control ${isValid && "error"}`}
                                            name="email"
                                            placeholder="  Email"
                                            id="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="message"></label>
                                        <textarea
                                            name="message"
                                            rows="8"
                                            className={`form-control ${isValid && "error"}`}
                                            id="message"
                                            placeholder="  Message"
                                            value={values.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>

                                    <div className="ml-lg-auto col-lg-5 col-12">
                                        <button type="submit"
                                                className={`form-control submit-btn ${loading && "loading"}`}>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                            >
                                                <div
                                                    id="loader"
                                                    className="spinner-border text-primary mr-3 d-none"
                                                    role="status"
                                                ></div>
                                                {loading ? "Sending..." : "Send Button"}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;