import React from 'react';
import {Footer, Navbar} from "../components";

function BaseLayout({children}) {
    return (
        <>
            <Navbar/>
            {children}
            <Footer/>
        </>
    );
}

export default BaseLayout;