import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";


function Projects() {
    return (
        <section className="project py-5" id="project">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 text-center mx-auto col-12">
                        <div className="col-lg-8 mx-auto title">
                            <h2>Projects I worked on as a backend Developer</h2>
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={{
                                nextEl: ".custom-button-next",
                                prevEl: ".custom-button-prev",
                              }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="item">
                                    <div className="project-info">
                                        <img
                                            src="./images/project/project-image01.svg"
                                            className="img-fluid"
                                            alt="project image"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="item">
                                    <div className="project-info">
                                        <img
                                            src="./images/project/project-image02.svg"
                                            className="img-fluid"
                                            alt="project image"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="item">
                                    <div className="project-info">
                                        <img
                                            src="./images/project/project-image03.svg"
                                            className="img-fluid"
                                            alt="project image"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        
                        <div class="custom-button-next">Next</div>
                        <div class="custom-button-prev">Prev</div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
