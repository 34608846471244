import BaseLayout from "./layouts/base-layout";
import {Features, Header, Projects, Contact, Skills} from "./sections";

function App() {
    return (
        <BaseLayout>
            <Header />
            <Projects />
            <Features />
            <Skills />
            <Contact />
        </BaseLayout>
    );
}

export default App;
