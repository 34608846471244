import React from 'react';
import {Link} from "react-scroll";

function Header() {
    return (
        <section
            className="about full-screen d-lg-flex justify-content-center align-items-center"
            id="about"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                        <div className="about-text">
                            <small className="small-text"
                            >Welcome to
                                <span className="mobile-block">my portfolio website!</span></small
                            >
                            <h1 className="animated animated-text">
                                <span className="h3">#bismillah<br/></span>
                                <span className="mr-2">Assalamu Alaikum<br/>I'm</span>
                                <div className="animated-info">
                                    <span className="animated-item">Abdulazizkhan</span>
                                    <span className="animated-item">Backend dev</span>
                                    <span className="animated-item">Teacher</span>
                                </div>
                            </h1>

                            <p>
                                I am not an experienced programmer, but I try to do the
                                assignments given by Allah’s permission on time and better than
                                said. <br/>
                                <a href="#contact" className="go-contact">Contact</a> me if you need
                                a backend programmer.
                            </p>

                            <div className="custom-btn-group mt-4">
                                <a
                                    href="./images/Abdulazizkhan.pdf"
                                    download
                                    className="btn mr-lg-2 custom-btn"
                                >
                                    <i className="uil bx bx-file"></i> Download Resume
                                </a>
                                <Link
                                    className="btn custom-btn custom-btn-bg"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-49} // Adjust the offset value to match your navbar height
                                    duration={700}
                                >
                                    Get a free quote
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="about-image svg">
                            <img
                                src="./images/undraw/workspace-full-view.png"
                                className="img-fluid"
                                alt="svg image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;